<template>
  <v-container class="pa-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="ml-10 mr-10 mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="contract"
                sort-by="id"
                class="elevation-1"
                style="background: #f5f5f5"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                show-expand
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <b>Id: </b> {{ item.id }} <br />
                    <b>Email Backend : </b> {{ item.email_backend }}<br />
                    <b>Email Host: </b> {{ item.email_host }}<br />
                    <b>Email Host User Name : </b> {{ item.email_host_user
                    }}<br />
                    <b>Email Host Port : </b> {{ item.email_port }}<br />
                    <b>Email Host Password : </b> {{ item.email_host_password
                    }}<br />
                  </td>
                </template>

                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-toolbar-title
                      style="margin-left: -14px"
                      class="mt-2 mb-2"
                      >Email Server Information</v-toolbar-title
                    >
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">Edit Email</span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="editedItem.email_backend"
                                label="Email Server"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="editedItem.email_host"
                                label=" Your Hosting Server"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="editedItem.email_host_user"
                                label="Your Hosting Email"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="editedItem.email_host_password"
                                label="Your Hosting Password"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="editedItem.email_port"
                                label="Email Port"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <template>
                              <v-container fluid>
                                <v-checkbox
                                  v-model="editedItem.Tls_value"
                                  :label="`Tls value: ${Tls_value.toString()}`"
                                ></v-checkbox>
                                <v-checkbox
                                  v-model="editedItem.Ssl_value"
                                  :label="`Ssl Value: ${Ssl_value.toString()}`"
                                ></v-checkbox>
                              </v-container>
                            </template>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="saveEdit"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialog2" max-width="500px">
                      <template v-slot:activator="{ on, attrs }" v-if>
                        <v-btn color="primary" float-right @click="addnew">
                          <v-icon color="white">mdi-plus</v-icon>Add New
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Add New Email</span>
                        </v-card-title>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="email_backend"
                                label="Email Server"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="email_host"
                                label=" Your Hosting Server"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="email_host_user"
                                label="Your Hosting Email"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="email_host_password"
                                label="Your Hosting Password"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <v-text-field
                                v-model="email_port"
                                label="Email Port"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <template>
                              <v-container fluid>
                                <v-checkbox
                                  v-model="Tls_value"
                                  :label="`Tls value: ${Tls_value.toString()}`"
                                ></v-checkbox>
                                <v-checkbox
                                  v-model="Ssl_value"
                                  :label="`Ssl Value: ${Ssl_value.toString()}`"
                                ></v-checkbox>
                              </v-container>
                            </template>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="confirm"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  data: () => ({
    dialog: false,
    dialog2: false,
    email_backend: "",
    email_host: "",
    email_host_user: "",
    email_host_password: "",
    email_port: "",
    Tls_value: true,
    Ssl_value: false,
    headers: [
      {
        text: "Hosting Email Information",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "Email Backend", value: "email_backend" },
      { text: "Email Host", value: "email_host" },
      { text: "Email Host User", value: "email_host_user" },
      { text: "Email Port", value: "email_port" },
      { text: "Actions", value: "actions", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
    contract: [],
    contact: {},
    editedIndex: -1,
    editedItem: {
      email_backend: "",
      email_host: "",
      email_host_user: "",
      email_host_password: "",
      email_port: "",
      Tls_value: true,
      Ssl_value: false,
    },
    defaultItem: {
      email_backend: "",
      email_host: "",
      email_host_user: "",
      email_host_password: "",
      email_port: "",
      Tls_value: true,
      Ssl_value: false,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New " : "Update Email Configure ";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog2(val) {
      val || this.close();
    },
  },

  methods: {
    addnew() {
      this.dialog2 = true;
    },
    initialize() {
      axios.get("email/all_config/").then((response) => {
 
        this.contract = response.data.data;
      });
    },

    confirm() {
      axios
        .post("email/config_email/", {
          email_backend: this.email_backend,
          email_host: this.email_host,
          email_host_user: this.email_host_user,
          email_host_password: this.email_host_password,
          email_port: this.email_port,
          Tls_value: this.Tls_value,
          Ssl_value: this.Ssl_value,
        })
        .then((response) => {
       
          this.close();
        });
    },

    editItem(item) {
      this.editedItem = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialog2 = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    saveEdit() {
      axios
        .post(`email/update_config/${this.editedItem.id}/`, this.editedItem)
        .then((response) => {
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
